import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import qualitySelector from '@silvermine/videojs-quality-selector';

export const VideoJS = props => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);

    const { options, onReady } = props;

    React.useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            qualitySelector(videojs);
            const player = (playerRef.current = videojs(videoElement, options, () => {
                console.log('player ready');
                onReady && onReady(player);
            }));
        } 
    }, [options, onReady]);

    return (
        <div data-vjs-player className="App">
            <video id='vjs-video' ref={videoRef} className='video-js vjs-big-play-centered' />
        </div>
    );
};
