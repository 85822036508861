import './App.css';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import React from 'react';
import { VideoJS } from './VideoJS';

const name = window.location.pathname;
const source = (type = 'hls', res = '') =>
    `https://live.ashm.ca/media/${type}${name === '/' ? null : name.toLocaleLowerCase()}${res ? '_' + res : ''}.${
        type === 'hls' ? 'm3u8' : 'mpd'
    }`;

function App() {
    const [offline, setOffline] = React.useState(false);
    const playerRef = React.useRef(null);

    const sources = [
        {
            src: source(),
            type: 'application/x-mpegURL',
            label: 'Source',
            selected: true,
        },
        {
            src: source('hls', '720p'),
            type: 'application/x-mpegURL',
            label: '720p',
        },
    ];

    const options = {
        autoplay: true,
        controls: true,
        sources,
        fluid: true,
    };

    const onReady = player => {
        playerRef.current = player;
        player.controlBar.removeChild('FullscreenToggle');
        player.controlBar.addChild('QualitySelector');
        player.controlBar.addChild('FullscreenToggle');
    };

    React.useEffect(() => {
        if (name !== '/') axios.get(source()).catch(() => setOffline(true));
    }, []);

    return (
        <>
            {name === '/' || offline ? (
                offline ? (
                    <h1 style={{ marginTop: '15%', textAlign: 'center', color: '#cccccc' }}>
                        Offline - check back later!
                    </h1>
                ) : (
                    <h1 style={{ marginTop: '15%', textAlign: 'center', color: '#ffffff' }}>Invalid URL Provided</h1>
                )
            ) : (
                <>
                    <MetaTags>
                        <title>Private Streams - {name.substring(1)}</title>
                        <meta property='og:type' content='video' />
                    </MetaTags>
                    <VideoJS options={options} onReady={onReady} />
                </>
            )}
        </>
    );
}

export default App;
/*
            {name === '/' || offline ? (
                offline ? (
                    <h1 style={{ marginTop: '15%', textAlign: 'center', color: '#cccccc' }}>
                        Offline - check back later!
                    </h1>
                ) : (
                    <h1 style={{ marginTop: '15%', textAlign: 'center', color: '#ffffff' }}>Invalid URL Provided</h1>
                )
            ) : (
                <>
                    <MetaTags>
                        <title>Private Streams - {name.substring(1)}</title>
                        <meta property='og:type' content='video' />
                    </MetaTags>
                    <div data-vjs-player>
                        <video ref={videoRef} id='stream' className='video-js vjs-theme-forest' />
                    </div>
                </>
            )}
*/
